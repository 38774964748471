$font: 'Dosis', sans-serif;

$white: #f5f5f5;
$light-gray: #EBEBEB;
$main: #7FC2C2;
$light-main: #8CD0D0;
$dark-main: #459A9A;

$size: 400px;
$half-size: 200px;

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #c7e6e6;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.spinner {
  position: relative;
  height: $size;
  width: $size;
  min-height: $size;
  min-width: $size;
  border-radius: 50%;
  box-shadow: 0px 0px 160px rgba(17, 17, 17, 0.25);
  overflow: hidden;
  
  &__body {
    position: absolute;
    border-top: $half-size solid transparent;
    border-right: $half-size solid $main;
    border-bottom: $half-size solid $main;
    border-left: $half-size solid $main;
    
    z-index: 200;
  }
  
  &__start-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 140px;
    width: 140px;
    border-radius: 50%;
    font-family: $font;
    font-weight: bold;
    font-size: 64px;
    color: $white;
    background-color: $main;
    outline: none;
    border: none;
    user-select: none;
    
    z-index: 300;
    /*
    transition: 
      height .1s ease-in,
      width .2s ease-in,
      color .1s ease-in,
      background-color .1s ease-in;
    */
    &:hover {
      height: 150px;
      width: 150px;
      background-color: $light-main;
      box-shadow: 0px 0px 44px rgba(17, 17, 17, 0.25);
    }
    
    &:active {
      height: 135px;
      width: 135px;
      color: $main;
      background-color: $dark-main;
      box-shadow: none;
      
      animation: shaking .15s ease-in-out infinite;
    }
  }
  
  &__input {
    position: absolute;
    bottom: 40px;
    left: 50%;
    padding: 2px 10px;
    transform: translatex(-50%);
    font-family: $font;
    font-size: 48px;
    font-weight: bold;
    color: $main;
    background: $white;
    border-radius: 36px;

    text-align: center;
    outline: none;
    border: none;
    
    z-index: 300;

    transition: .1s ease-in;

    &:hover {
      color: $light-main;
    }

    &:focus {
      color: $dark-main;
    }
  }
  
  &__plate {
    position: absolute;
    height: $size;
    width: $size;
    
    /* transition: 2s ease-out;*/
  }
  
  &__plate--spin{
    animation: spinning 2s ease-out;
  }
  
  &__item {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translatex(-50%);
    height: calc(#{$half-size} - 40px);
    padding-top: 40px;
    font-family: $font;
    font-weight: bold;
    font-size: 48px;
    color: $dark-main;
    text-align: center;
  
    transform-origin: 50% 100%;
    
    z-index: 10;
  
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translatex(-50%);
      border-top: $half-size solid $white;
      border-right: $half-size solid transparent;
      border-bottom: $half-size solid transparent;
      border-left: $half-size solid transparent;
      
      z-index: -1;
    }  
  
    &:nth-child(even):before {
      border-top: $half-size solid $light-gray;
    }
  }
  
  &__item--left {
    transform: translatex(-50%) rotate(-90deg);
  }

  &__item--top {
      transform: translatex(-50%) rotate(0deg);
  }

  &__item--right {
      transform: translatex(-50%) rotate(90deg);
  }

  &__item--bottom {
      transform: translatex(-50%) rotate(180deg);
  }
}

@keyframes shaking {
  0% { 
    top: calc(50% + 1px); 
    left: calc(50% - 1px); 
  }
  25% { 
    top: calc(50% - 1px); 
    left: calc(50% + 1px); 
  }
  50% { 
    top: calc(50% + 1px); 
    left: calc(50% + 1px);
  }
  75% { 
    top: calc(50% - 1px); 
    left: calc(50% - 1px); 
  }
}

@keyframes spinning {
  from { 
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1800deg);
  }
}

.p-autocomplete-panel
{
  z-index: 110000;
}
.custom-autocomplete-panel .p-autocomplete-item.p-highlight {
  background-color: #f0f0f0; /* Change the background color for focused items */
  color: #333; /* Change the text color for focused items */
}


.auctionteam:focus {
  border-color: blue; /* Change border color when focused */
}

.auctionteam select:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.aligned-image {
  position: absolute;
  left: 10;
  top: 50px;
}

.aligned-image_logo2 {
  position: absolute;
  left: 10;
  top: 190px;
}

.aligned-image-right
{
  position: absolute;
  left: 80%;
  top: 80px;
}

.stat-item
{
  font-size: 20px;
}

.complted_team
{
  font-size: 18px;
  
}
/* Regular 3D Effect */
/* Regular 3D Effect with Reduced Padding */
.teamlist_right {
  font-size: 21px;
  border: 1px solid #ddd;
  background: linear-gradient(145deg, #ffffff, #e6e6e6); /* Light gradient for 3D effect */
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2), -3px -3px 4px rgba(255, 255, 255, 0.8); /* Slightly reduced shadow */
  border-radius: 3px; /* Tighter corner radius */
  padding: 2px; /* Reduced padding to save space */
  text-align: center; /* Centered content */
  transition: transform 0.2s, box-shadow 0.2s;
  color: #28a745;
}

.teamlist_right:hover {
  transform: translateY(-2px); /* Slightly less lift */
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25), -4px -4px 5px rgba(255, 255, 255, 0.9); /* Enhanced shadow on hover */
}


/* Disabled Team - Flat Appearance */
.disabled_team {
  font-size: 16px;
  border: 1px solid #ddd;
  color: #f0f0f0; /* Flat, dull background */
  background: #dc3545; /* Muted text color */
  box-shadow: none; /* Remove shadow for flat appearance */
  pointer-events: none; /* Disable interactions */
  opacity: 0.6; /* Reduce opacity for a faded effect */
  border-radius: 5px;
  text-align: center;
}

.teamlist_completed{
  font-size: 16px;
  border: 1px solid #ddd;
  color: #777777; /* Flat, dull background */
  background: #8B0000; /* Muted text color */
  box-shadow: none; /* Remove shadow for flat appearance */
  pointer-events: none; /* Disable interactions */
  opacity: 0.6; /* Reduce opacity for a faded effect */
  border-radius: 5px;
  text-align: center;
}


.stat-item {
  padding: 5px 5px;
  margin: 10px;
  text-align: center;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  color: #333;
}

.stat-item h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.stat-item.playername {
  background-color: #424242;
  color: #E0F7FA;
  font-size: 1.5rem; /* Increased font size */
  font-weight: bold;
  border: 3px solid #1e88e5; /* Thicker border */
  text-transform: uppercase;
  box-shadow: 0px 0px 10px 2px #1e88e5; /* Glowing effect */
  padding: 15px; /* Extra padding for prominence */
  transition: all 0.3s ease-in-out; /* Smooth hover effect */
  animation: blink 6s infinite; /* Apply blink animation */

}

.stat-item.playername:hover {
  box-shadow: 0px 0px 15px 5px #64b5f6; /* Stronger glow on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}


.stat-item.icon {
  background-color: #ffee58;
  color: #424242;
  font-size: 1.2rem;
  font-weight: bold;
  border: 2px solid #fbc02d;
}


.stat-item1 {
  padding: 2px 2px;
  margin: 10px;
  text-align: center;
  border-radius: 2px;
  font-family: Arial, sans-serif;
  font-size: medium;
}

.stat-item1.batting {
  background-color: #c8e6c9; /* Lighter green */
  color: #4caf50; /* Softer green */
  font-weight: normal; /* Reduce boldness */
  border: 1px solid #66bb6a; /* Lighter border */
}

.stat-item1.bowling {
  background-color: #e3f2fd; /* Lighter blue */
  color: #1976d2; /* Softer blue */
  font-weight: normal; /* Reduce boldness */
  border: 1px solid #64b5f6; /* Lighter border */
}

.stat-item1.matches {
  background-color: #fff3e0; /* Lighter orange */
  color: #e64a19; /* Softer orange */
  font-weight: normal; /* Reduce boldness */
  border: 1px solid #ffab91; /* Lighter border */
}

.stat-item1.runs {
  background-color: #ffe0b2; /* Softer orange */
  color: #f4511e; /* Softer color */
  font-weight: normal; /* Reduce boldness */
  border: 1px solid #ff7043; /* Lighter border */
}

.stat-item1.wickets {
  background-color: #f3e5f5; /* Lighter purple */
  color: #8e24aa; /* Softer purple */
  font-weight: normal; /* Reduce boldness */
  border: 1px solid #ce93d8; /* Lighter border */
}


.player-number {
  color: #fc0328;
  font-weight: bold;
  font-size: 2.3rem;
}

/* Keyframes for blinking effect */
@keyframes blink {
  0%, 100% {
      opacity: 1; /* Fully visible */
  }
  50% {
      opacity: 0.20; /* Invisible */
  }
}


.marquee {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  animation: marquee 15s linear infinite;
}

.marquee span {
  margin-right: 2rem; /* Spacing between items */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.recent
{
  color: #3b5998;
}
.topsell
{
  color: #81c784;
}
.refresh-icon {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10000000;
}
/*
.pending-players > div:hover {
  transform: scale(1.05);
  transition: all 0.3s;
}*/
.pending-players
{
  font-size: 16px;
}

.image-zoom-container {
  max-height: 420px; /* Set your desired maximum height */
  overflow: hidden;
}

.image-zoom-container img {
  max-height: 100%; /* Ensure the image scales within the container */
  object-fit: contain; /* Maintain aspect ratio */
}


.player-card {
  background-color: #f9f9ff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.player-card img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.player-info {
  flex-grow: 1;
}
.player-info h5 {
  margin: 0;
  font-weight: bold;
}
.player-info p {
  margin: 2px 0;
  font-size: 14px;
  color: #6c757d;
}
.team-logo {
  width: 50px;
  height: 50px;
}
.sold-section {
  background-color: #f3f8ff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1px;
}
.sold-section .status {
  color: #28a745;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
}
.sold-section .status svg {
  width: 16px;
  height: 16px;
}
.sold-section .price {
  font-weight: bold;
  color: #4c4cff;
}