body {
    background-color: #f8f9fa;
  }
  
  .refresh-icon {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }
  
  .current-player-highlight {
    background: linear-gradient(45deg, #007bff, #6610f2);
  }
  
  .top-bids {
    background: linear-gradient(45deg, #dc3545, #ff5733);
  }
  
  .team {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .pending-players .row > div:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }
  
  .modal.fade {
    background: rgba(0, 0, 0, 0.5);
  }
  .bg-light-primary {
    background-color: #e3f2fd; /* Light blue */
    color: #0d6efd; /* Primary text */
  }
  .bg-light-success {
    background-color: #e9f7ef; /* Light green */
    color: #198754; /* Success text */
  }
  .text-white1
  {
      color: #333;
  }

  /* Regular 3D Effect */
/* Regular 3D Effect with Reduced Padding */
.teamlist_right {
  font-size: 21px;
  border: 1px solid #ddd;
  background: linear-gradient(145deg, #ffffff, #e6e6e6); /* Light gradient for 3D effect */
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2), -3px -3px 4px rgba(255, 255, 255, 0.8); /* Slightly reduced shadow */
  border-radius: 3px; /* Tighter corner radius */
  padding: 2px; /* Reduced padding to save space */
  text-align: center; /* Centered content */
  transition: transform 0.2s, box-shadow 0.2s;
  color: #28a745;
}

.teamlist_right:hover {
  transform: translateY(-2px); /* Slightly less lift */
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25), -4px -4px 5px rgba(255, 255, 255, 0.9); /* Enhanced shadow on hover */
}


/* Disabled Team - Flat Appearance */
.disabled_team {
  font-size: 16px;
  border: 1px solid #ddd;
  color: #f0f0f0; /* Flat, dull background */
  background: #dc3545; /* Muted text color */
  box-shadow: none; /* Remove shadow for flat appearance */
  pointer-events: none; /* Disable interactions */
  opacity: 0.6; /* Reduce opacity for a faded effect */
  border-radius: 5px;
  text-align: center;
}

.teamlist_completed{
  font-size: 16px;
  border: 1px solid #ddd;
  color: #777777; /* Flat, dull background */
  background: #8B0000; /* Muted text color */
  box-shadow: none; /* Remove shadow for flat appearance */
  pointer-events: none; /* Disable interactions */
  opacity: 0.6; /* Reduce opacity for a faded effect */
  border-radius: 5px;
  text-align: center;
}

.blink {
  animation: blink-animation 0.5s ease-in-out infinite alternate;
}

@keyframes blink-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
